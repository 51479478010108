import { ExclamationCircleOutlined, PrinterOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Drawer, Empty, Flex, Grid, Image, Modal, Skeleton, Table, TableColumnsType, Tabs, Typography, notification } from "antd";
import { api } from "api";
import { useLazyGetSalesOrderInvoiceQuery } from "api/sales-order/sales-order-api";
import { useDeleteScaleMutation, useGetOneScaleBySalesOrderQuery } from "api/sales-order/scale-api";
import { OBJ_COLOR } from "commons/constants";
import { dateFormat, getUrlImage } from "commons/constants/util";
import dayjs from "dayjs";
import * as pdfMake from "pdfmake/build/pdfmake.js";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";

const { useBreakpoint } = Grid;

const getTransferNo = (number: number) => {
	const max = 7;
	const length = number.toString().length;

	return (
		Array(max - length)
			.fill("0")
			.join("") + number
	);
};

const getTitleColumn = (gender: string) => {
	if (gender === "Mái") {
		return ["SL/M", "TL/M"];
	}

	if (gender === "Trống") {
		return ["SL/T", "TL/T"];
	}

	return ["SL/X", "TL/X"];
};

const renderColumnText = (text_1: string, text_2: string[]) => {
	const textShift = text_2.shift();
	const textPop = text_2.pop();

	const widthTextShift = 100;
	const widthTextPop = 30;
	const columnGap = 10;

	const length = text_2.length;

	if (length > 1) {
		const widthText = 50;

		const width = widthTextShift + widthTextPop + columnGap * 2 + length * widthText;

		return {
			columns: [
				{
					width: "*",
					text: text_1,
				},
				{
					width,
					columns: [
						{ width: widthTextShift, text: textShift, alignment: "right" },
						{
							columns: text_2.map((text) => ({ width: widthText, text })),
							columnGap,
						},
						{ width: widthTextPop, text: textPop },
					],
					columnGap,
				},
			],
			style: "column",
		};
	}

	const widthText = 100;
	const width = widthTextShift + widthTextPop + columnGap * 2 + widthText;

	return {
		columns: [
			{
				width: "*",
				text: text_1,
			},
			{
				width,
				columns: [
					{ width: widthTextShift, text: textShift, alignment: "right" },
					{
						columns: text_2.map((text) => ({ width: widthText + 10, text, alignment: "center" })),
						columnGap,
					},
					{ width: widthTextPop, text: textPop },
				],
				columnGap,
			},
		],
		style: "column",
	};
};

const renderWidthsTable = (cols: string[]) => {
	return cols.map((str) => (str.indexOf("STT") !== -1 ? 15 : str.indexOf("SL") !== -1 ? 20 : 26));
};

const renderHeaderTable1 = (cols: string[]) => {
	return cols.map((str, index) => {
		return { text: "Số lượng (con)/Trọng Lượng thực giao (Kg)", alignment: "center", fontSize: 8, bold: true, colSpan: cols.length };
	});
};

const renderHeaderTable2 = (cols: string[]) => {
	const headers = cols.map((str, index) => {
		return { text: str.split("-")[0], fontSize: 8, bold: true, alignment: "center" };
	});

	return headers;
};

const renderBodyTable = (data: any[], cols: string[]) => {
	const object: any = {};

	data.forEach((item) => {
		for (const key in item) {
			if (Object.prototype.hasOwnProperty.call(item, key)) {
				const value = Number(item[key]) || 0;

				if (!object[key]) {
					object[key] = key === "key" ? "TC" : value;
				} else {
					if (key !== "key") object[key] = +((Number(object[key]) || 0) + value).toFixed(2);
				}
			}
		}
	});

	const newData = [...data, object];

	const result = newData.map((item) => {
		const columns: any[] = [];

		cols.forEach((str, index) => {
			const value = item[str === "STT" ? "key" : str] || "";
			columns.push({ text: value, fontSize: 8, bold: true, alignment: "center" });
		});

		return columns;
	});
	return result;
};

interface HistoryScaleDrawerProps {
	open: boolean;
	onClose: () => void;
	salesOrderId: number;
	salesOrderDetailId: number;
}

const HistoryScaleDrawer = ({ open, onClose, salesOrderId, salesOrderDetailId }: HistoryScaleDrawerProps) => {
	const screens = useBreakpoint();

	const user = useAppSelector((state) => state.user);

	const dispatch = useAppDispatch();

	const { data, isFetching } = useGetOneScaleBySalesOrderQuery({ salesOrderId, salesOrderDetailId }, { skip: !salesOrderDetailId, refetchOnMountOrArgChange: true });

	const [getSalesOrderInvoice, { isFetching: isFetchingSalesOrderInvoice }] = useLazyGetSalesOrderInvoiceQuery();

	const [deleteScale] = useDeleteScaleMutation();

	const [scale, historyChickenScale] = useMemo(() => {
		if (!data?.data) return [null, [], []];

		const { scale, historyChickenScale } = data.data;

		return [scale, historyChickenScale];
	}, [data]);

	const [dataSource, cols] = useMemo(() => {
		const groupData: { [key: string]: HistoryChickenScale[] } = {};

		historyChickenScale.forEach((item) => {
			if (!groupData[item.gender]) {
				groupData[item.gender] = [item];
			} else {
				groupData[item.gender] = [item, ...groupData[item.gender]];
			}
		});

		if (!Object.keys(groupData).length) return [[], []];

		const array = Array(30).fill(99);

		let max = 0;

		Object.values(groupData).map((items) => {
			let count = 1;
			let index = 0;

			items.forEach((item) => {
				const { quantity, weight, gender } = item;

				if (index === 30) {
					count++;
					index = 0;
				}

				const object: any = {};

				const [keyQuantity, keyWeight] = getTitleColumn(gender);

				object[`${keyQuantity}-quantity-${count}`] = quantity;
				object[`${keyWeight}-weight-${count}`] = weight;

				array[index] = { ...array[index], ...object };

				index++;
			});

			if (count > max) {
				max = count;
			}
		});

		const length = 16 - Object.keys(array[0]).length;

		const cols = [
			...Object.keys(array[0]),
			...Array(length)
				.fill(99)
				.map((_, index) => (index % 2 === 0 ? `SL-${index}` : `TL-${index}`)),
		];

		return [array.map((item, index) => ({ key: index + 1, ...item })), cols];
	}, [historyChickenScale]);

	const newCols = useMemo(() => {
		const newCols = [...cols];

		newCols.unshift("STT");

		return newCols;
	}, [cols]);

	const columns: TableColumnsType<any> = useMemo(() => {
		return [
			{
				title: "Số lượng (con)/Trọng Lượng thực giao (Kg)",
				children: [
					{
						title: "STT",
						dataIndex: "key",
						align: "center",
					},
					...cols.map((str) => ({
						title: <Typography.Text style={{ whiteSpace: "nowrap" }}>{str.split("-")[0]}</Typography.Text>,
						dataIndex: str,
						align: "center",
					})),
				],
			},
		];
	}, [dataSource]);

	const quantityCase = useMemo(() => {
		if (!scale) return [];

		const data: string[] = [];

		const { totalFemaleCase, totalMaleCase, totalNoneCase } = scale;

		if (totalFemaleCase) {
			data.push(`M ${totalFemaleCase}`);
		}
		if (totalMaleCase) {
			data.push(`T ${totalMaleCase}`);
		}

		if (totalNoneCase) {
			data.push(`X ${totalNoneCase}`);
		}

		return data;
	}, [scale]);

	const quantityChicken = useMemo(() => {
		if (!scale) return [];

		const data: string[] = [];

		const { totalFemaleQty, totalMaleQty, totalNoneQty } = scale;

		if (totalFemaleQty) {
			data.push(`M ${totalFemaleQty}`);
		}
		if (totalMaleQty) {
			data.push(`T ${totalMaleQty}`);
		}

		if (totalNoneQty) {
			data.push(`X ${totalNoneQty}`);
		}

		return data;
	}, [scale]);

	const weight = useMemo(() => {
		if (!scale) return [];

		const data: string[] = [];

		const { totalFemaleWeight, totalMaleWeight, totalNoneWeight } = scale;

		if (totalFemaleWeight) {
			data.push(`M ${totalFemaleWeight}`);
		}
		if (totalMaleWeight) {
			data.push(`T ${totalMaleWeight}`);
		}

		if (totalNoneWeight) {
			data.push(`X ${totalNoneWeight}`);
		}

		return data;
	}, [scale]);

	const caseWeight = useMemo(() => {
		if (!scale) return [];

		const data: string[] = [];

		const { totalFemaleCaseWeight, totalMaleCaseWeight, totalNoneCaseWeight } = scale;

		if (totalFemaleCaseWeight) {
			data.push(`M ${totalFemaleCaseWeight}`);
		}
		if (totalMaleCaseWeight) {
			data.push(`T ${totalMaleCaseWeight}`);
		}

		if (totalNoneCaseWeight) {
			data.push(`X ${totalNoneCaseWeight}`);
		}

		return data;
	}, [scale]);

	const weightNet = useMemo(() => {
		if (!scale) return [];

		const data: string[] = [];

		const { totalFemaleWeightNet, totalMaleWeightNet, totalNoneWeightNet } = scale;

		if (totalFemaleWeightNet) {
			data.push(`M ${totalFemaleWeightNet}`);
		}
		if (totalMaleWeightNet) {
			data.push(`T ${totalMaleWeightNet}`);
		}

		if (totalNoneWeightNet) {
			data.push(`X ${totalNoneWeightNet}`);
		}

		return data;
	}, [scale]);

	const sumWeightNet = useMemo(() => {
		if (!scale) return "0";

		const { totalFemaleWeightNet = 0, totalMaleWeightNet = 0, totalNoneWeightNet = 0 } = scale;

		return (totalFemaleWeightNet + totalMaleWeightNet + totalNoneWeightNet).toFixed(2);
	}, [scale]);

	const averageWeightCase = useMemo(() => {
		if (!scale) return "0";

		const { totalFemaleCaseWeight = 0, totalMaleCaseWeight = 0, totalNoneCaseWeight = 0, totalFemaleCase = 0, totalMaleCase = 0, totalNoneCase = 0 } = scale;

		return ((totalFemaleCaseWeight + totalMaleCaseWeight + totalNoneCaseWeight) / (totalFemaleCase + totalMaleCase + totalNoneCase)).toFixed(2);
	}, [scale]);

	const weightScaleAverage = useMemo(() => {
		return (+sumWeightNet / (scale?.totalQty || 0)).toFixed(2);
	}, [sumWeightNet, scale?.totalQty]);

	const handleOnClick = () => {
		if (!scale) return;

		const pdf = pdfMake.createPdf(
			{
				content: [
					{
						columns: [
							{
								text: "CÔNG TY TNHH LINKFARM",
								fontSize: 11,
								bold: true,
							},
							{
								width: 110,
								text: `Số Phiếu: ${getTransferNo(scale.transferNo)}-${scale.transferNoSub}`,
								fontSize: 11,
							},
						],
						style: "column",
					},
					{
						columns: [
							{
								width: "auto",
								text: "Địa chỉ:",
								fontSize: 11,
							},
							{
								width: "*",
								text: "Thôn 1, Xã Hồng Sơn, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
								fontSize: 11,
								bold: true,
							},
							{
								width: 110,
								// text: `Ngày: ${dateFormat(dayjs())}`,
								text: `Ngày: ${dateFormat(scale.endTimeScale)}`,
								fontSize: 11,
							},
						],
						style: "column",
						columnGap: 3,
					},
					{
						columns: [
							{
								width: "auto",
								text: "Mã số thuế:",
								fontSize: 11,
							},
							{
								width: "*",
								text: "4101491062",
								fontSize: 11,
								bold: true,
							},
						],
						style: "column",
						columnGap: 3,
					},
					{
						stack: ["PHIẾU XUẤT KHO KIÊM BIÊN BẢN GIAO NHẬN"],
						bold: true,
						fontSize: 12,
						alignment: "center",
						margin: [0, 0, 0, 5],
					},
					renderColumnText(`Giờ bắt đầu cân: ${dateFormat(scale.startTimeScale, true)}`, ["Tên hàng:", "Gà thịt", ""]),
					renderColumnText(`Giờ kết thúc cân: ${dateFormat(scale.endTimeScale, true)}`, ["Số lồng gà:", ...quantityCase, "Lồng"]),
					renderColumnText(`Trại xuất: ${scale.farmName}`, ["Tổng số con:", ...quantityChicken, "Con"]),
					renderColumnText("Trại nhập:", ["Tổng TL chưa trừ bì:", ...weight, "Kg"]),
					renderColumnText(`Mã khách hàng: ${scale.customerId}`, ["Trừ bì:", ...caseWeight, "Kg"]),
					renderColumnText(`Số điện thoại: ${scale.custPhone}`, ["Tổng TL đã trừ bì:", ...weightNet, "Kg"]),
					renderColumnText(`Tên khách hàng: ${scale.customerName}`, ["Tổng TL đã trừ bì:", sumWeightNet, "Kg"]),
					renderColumnText(`Địa chỉ: ${scale.shipAddress}`, ["TLBQ lồng không:", averageWeightCase, "Kg"]),
					renderColumnText(`Tên tài xế: ${scale.driver}`, ["TL Bình Quân", weightScaleAverage, "Kg"]),
					{
						columns: [
							{
								width: 140,
								text: `Số xe: ${scale.truckId}`,
							},
							{
								width: 140,
								text: "M: Mái T: Trống X: Xô",
							},
							{
								width: 140,
								text: `Loại Gà: ${scale.chickenType}`,
							},
						],
						columnGap: 8,
						style: "column",
					},
					{
						margin: [0, 5, 0, 0],
						table: {
							headerRows: 1,
							widths: renderWidthsTable(newCols),
							body: [renderHeaderTable1(newCols), renderHeaderTable2(newCols), ...renderBodyTable(dataSource, newCols)],
						},
					},
					{
						alignment: "justify",
						columns: [
							{ text: "Người lập", fontSize: 11, bold: true, alignment: "center", margin: [0, 5, 0, 0] },
							{ text: "Đại diện bên trại", fontSize: 11, bold: true, alignment: "center", margin: [0, 5, 0, 0] },
							{ text: "Đại diện bên mua", fontSize: 11, bold: true, alignment: "center", margin: [0, 5, 0, 0] },
						],
						style: "column",
					},
					{
						alignment: "justify",
						columns: [
							{
								text: "(Ký, ghi rõ họ tên)",
								italics: true,
								alignment: "center",
							},
							{
								text: "(Ký, ghi rõ họ tên)",
								italics: true,
								alignment: "center",
							},
							{
								text: "(Ký, ghi rõ họ tên)",
								italics: true,
								alignment: "center",
							},
						],
						style: "column",
					},
				],
				styles: {
					column: {
						fontSize: 9,
						margin: [0, 0, 0, 2],
					},
				},
			} as any,
			undefined,
			undefined,
			pdfFonts.pdfMake.vfs
		);
		pdf.download(`${scale.customerId}_${scale.transferNo}_${scale.transferNoSub}`);
	};

	const handleOnSeeInvoice = async () => {
		try {
			const result = await getSalesOrderInvoice(salesOrderId).unwrap();
			window.open(result.UrlPDF, "_blank");
		} catch (error) {
			notification.error({
				message: "Chưa có hoá đơn, vui lòng thử lại.",
			});
		}
	};

	const handleDeleteScale = () => {
		if (!scale) return;
		Modal.confirm({
			title: "Xác nhận xoá phiếu cân",
			icon: <ExclamationCircleOutlined />,
			content: "Hành vi này không thể thu hồi lại phiếu cân.",
			okText: "Đồng ý",
			cancelText: "Huỷ",
			onOk: async () => {
				try {
					await deleteScale(scale?.scaleId).unwrap();
					dispatch(api.util.invalidateTags([{ type: "scale", id: `${salesOrderId}${salesOrderDetailId}` }]));
					notification.success({ message: "Xoá thành công." });
					onClose();
				} catch (error) { }
			},
		});
	};

	return (
		<Drawer title={`Thông tin phiếu cân`} open={open} width={"100%"} onClose={onClose}>
			{!isFetching && !scale ? (
				<Empty />
			) : (
				<Flex vertical gap={40} justify="center" style={{ width: screens.xxl ? "60%" : screens.xl ? "90%" : "100%", margin: "auto" }}>
					<Flex vertical gap={20}>
						<Flex vertical gap={20}>
							<Flex justify="space-between" align="center">
								<Typography.Text style={{ color: OBJ_COLOR.SUCCESS, fontSize: 16, fontWeight: 600 }}>Thông tin phiếu cân</Typography.Text>
								{/* <Button loading={isFetchingSalesOrderInvoice} type="primary" icon={<EyeOutlined />} ghost onClick={handleOnSeeInvoice}>
									Xem hoá đơn
								</Button> */}
							</Flex>
							<Skeleton active loading={isFetching}>
								<Flex
									gap={20}
									vertical
									style={{
										padding: 20,
										border: "1px solid #efefef",
										borderRadius: 8,
									}}>
									<Flex vertical={screens.xs ? true : false} justify="space-between">
										<Flex gap={12} justify={screens.xs ? "flex-start" : "center"}>
											<Flex vertical gap={6}>
												<Typography.Text type="secondary">Giờ bắt đầu cân:</Typography.Text>
												<Typography.Text type="secondary">Giờ kết thúc cân:</Typography.Text>
												<Typography.Text type="secondary">Trại xuất:</Typography.Text>
												<Typography.Text type="secondary">Trại nhập:</Typography.Text>
												<Typography.Text type="secondary">Mã khách hàng:</Typography.Text>
												<Typography.Text type="secondary">Số điện thoại:</Typography.Text>
												<Typography.Text type="secondary">Tên khách hàng:</Typography.Text>
												<Typography.Text type="secondary">Địa chỉ giao hàng:</Typography.Text>
												<Typography.Text type="secondary">Tên tài xế:</Typography.Text>
												<Typography.Text type="secondary">Số xe:</Typography.Text>
											</Flex>
											<Flex vertical gap={6}>
												<Typography.Text>{dateFormat(scale?.startTimeScale as string, true)}</Typography.Text>
												<Typography.Text>{dateFormat(scale?.endTimeScale as string, true)}</Typography.Text>
												<Typography.Text>{scale?.farmName}</Typography.Text>
												<Typography.Text>-</Typography.Text>
												<Typography.Text>{scale?.customerId}</Typography.Text>
												<Typography.Text>{scale?.custPhone}</Typography.Text>
												<Typography.Text>{scale?.customerName}</Typography.Text>
												<Typography.Text>{scale?.shipAddress}</Typography.Text>
												<Typography.Text>{scale?.driver}</Typography.Text>
												<Typography.Text>{scale?.truckId}</Typography.Text>
											</Flex>
										</Flex>
										<Flex gap={20} justify="space-between">
											<Flex vertical align={screens.xs ? "flex-start" : "flex-end"} gap={6}>
												<Typography.Text type="secondary">Tên hàng:</Typography.Text>
												<Typography.Text type="secondary">Số lồng gà:</Typography.Text>
												<Typography.Text type="secondary">Tổng số con:</Typography.Text>
												<Typography.Text type="secondary">Tổng TL chưa trừ bì:</Typography.Text>
												<Typography.Text type="secondary">Trừ bì:</Typography.Text>
												<Typography.Text type="secondary">Tổng TL đã trừ bì:</Typography.Text>
												<Typography.Text type="secondary">Tổng TL đã trừ bì:</Typography.Text>
												<Typography.Text type="secondary">TLBQ lồng không:</Typography.Text>
												<Typography.Text type="secondary">TL Bình Quân:</Typography.Text>
											</Flex>
											<Flex vertical gap={6}>
												<Typography.Text style={{ textAlign: "center" }}>Gà Thịt</Typography.Text>
												<Flex justify="space-around" gap={20}>
													{quantityCase.map((str) => (
														<Typography.Text>{str}</Typography.Text>
													))}
												</Flex>
												<Flex justify="space-around" gap={20}>
													{quantityChicken.map((str) => (
														<Typography.Text>{str}</Typography.Text>
													))}
												</Flex>
												<Flex justify="space-around" gap={20}>
													{weight.map((str) => (
														<Typography.Text>{str}</Typography.Text>
													))}
												</Flex>
												<Flex justify="space-around" gap={20}>
													{caseWeight.map((str) => (
														<Typography.Text>{str}</Typography.Text>
													))}
												</Flex>
												<Flex justify="space-around" gap={20}>
													{weightNet.map((str) => (
														<Typography.Text>{str}</Typography.Text>
													))}
												</Flex>
												<Typography.Text style={{ textAlign: "center" }}>{sumWeightNet}</Typography.Text>
												<Typography.Text style={{ textAlign: "center" }}>{averageWeightCase}</Typography.Text>
												<Typography.Text style={{ textAlign: "center" }}>{weightScaleAverage}</Typography.Text>
											</Flex>
											<Flex vertical gap={6}>
												<Typography.Text>-</Typography.Text>
												<Typography.Text>Lồng</Typography.Text>
												<Typography.Text>Con</Typography.Text>
												<Typography.Text>Kg</Typography.Text>
												<Typography.Text>Kg</Typography.Text>
												<Typography.Text>Kg</Typography.Text>
												<Typography.Text>Kg</Typography.Text>
												<Typography.Text>Kg</Typography.Text>
												<Typography.Text>Kg</Typography.Text>
											</Flex>
										</Flex>
									</Flex>

									<Flex vertical={screens.xs ? true : false} gap={10} justify="space-between" align={screens.xs ? "flex-start" : "center"}>
										<Flex gap={20}>
											<Typography.Text>M: Mái</Typography.Text>
											<Typography.Text>T: Trống</Typography.Text>
											<Typography.Text>X: Xô</Typography.Text>
										</Flex>
										<Typography.Text>Loại Gà: {scale?.chickenType}</Typography.Text>
										<Flex gap={10}>
											<Button onClick={handleOnClick} type="primary" icon={<PrinterOutlined />}>
												In Phiếu
											</Button>
											{/* {user?.role === UserRole.Accountant && (
												<Button onClick={handleDeleteScale} type="primary" danger icon={<DeleteOutlined />}>
													Xoá Phiếu
												</Button>
											)} */}
										</Flex>
									</Flex>
								</Flex>
							</Skeleton>
						</Flex>
					</Flex>
					<Tabs
						items={[
							{
								label: "Lịch sử cân",
								key: "item-1",
								children: <Table loading={isFetching} bordered columns={columns} dataSource={dataSource} scroll={{ x: true }} pagination={false} />,
							},
							{
								label: "Hình ảnh",
								key: "item-2",
								children: (
									<Flex gap={20} wrap="wrap">
										{scale?.images.map((src) => (
											<Image style={{ border: "1px solid rgb(239, 239, 239)", borderRadius: 6 }} width={180} height={180} src={getUrlImage(src)} />
										))}
									</Flex>
								),
							},
						]}
					/>
				</Flex>
			)}
		</Drawer>
	);
};

export default HistoryScaleDrawer;
