import { api } from "api/baseApi";
import dayjs from "dayjs";
import qs from "query-string";

const url = "/scale";

export const { useGetOneScaleBySalesOrderQuery, useDeleteScaleMutation, useLazyGetReportScaleQuery, useLazyGetExcelReportScaleQuery } = api.injectEndpoints({
	endpoints: (build) => ({
		getReportScale: build.query<ReportScale[], Record<string, any>>({
			query: (query) => {
				return `${url}/report?${qs.stringify(query)}`;
			},
		}),
		getOneScaleBySalesOrder: build.query<
			ScaleBySalesOrder,
			{
				salesOrderId: number;
				salesOrderDetailId: number;
			}
		>({
			query: ({ salesOrderId, salesOrderDetailId }) => `${url}/${salesOrderId}/${salesOrderDetailId}`,
			providesTags: (result, _, { salesOrderId, salesOrderDetailId }) => [{ type: "scale", id: `${salesOrderId}${salesOrderDetailId}` }],
		}),
		deleteScale: build.mutation<void, string>({
			query: (scaleId) => ({ url: `${url}/${scaleId}`, method: "DELETE" }),
		}),
		getExcelReportScale: build.query<ReportScale[], Record<string, any>>({
			query: (query) => {
				return {
					url: `${url}/export-report?${qs.stringify(query)}`,
					responseHandler: async (response) => {
                        var blobPDF = window.URL.createObjectURL(await response.blob());
                        var hiddenElement = document.createElement('a');
                        hiddenElement.href = blobPDF;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = `scale-report-${dayjs().format("DD-MM-YYYY")}`;
                        hiddenElement.click();
                    },
                    cache: "no-cache",
				}
			},
		}),
	}),
});
